import React from "react";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { List, TextRow, InfoContainer, Title, Text } from "./style";

function CieInfoBox() {
  return (
    <InfoContainer>
      <Title>Riconoscimento con CIE</Title>
      <TextRow>
        <FontAwesomeIcon className="icon" icon={faClock} />
        <Text>
          Tempo indicativo per il completamento: <strong>5 minuti</strong>
        </Text>
      </TextRow>
      <Text>
        Proseguendo con questo metodo, per portare a termine la procedura
        (totalmente da remoto) il formatario dovrà avere:
      </Text>
      <List> Un'identità personale CIE</List>
    </InfoContainer>
  );
}

export default CieInfoBox;
