import styled from "styled-components";

export const ControlsContainer = styled.div`
margin-top: 2rem;
display: flex;
flex-direction: row;
align-items: center;
justify-content: end;
width: 100%;

  .page-controls {  
    display: flex;
    flex-direction: row;
    justifyContent: end;
  }

  .row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .pages-input {
    width: 4rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .left-arrow {
    margin-left: 1rem;
    margin-right: 1.5rem;
    color: #0090d1;
    cursor: pointer;
  }

  .right-arrow {
    color: #0090d1;
    cursor: pointer;
  }

  .margin-left-4 {
    margin-left: 4rem;
  }

  .row-per-page-select {
    width: 4rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;
