import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faEdit,
  faArchive,
  faCircleMinus,
  faCircleCheck,
  faTriangleExclamation
} from "@fortawesome/free-solid-svg-icons";
import { getLogSignatory } from "../../../../api/backofficeAPI";
import { dateFormatter } from "../../../../utils/functions";
import SignatoryModal from "./SignatoryModal";
import styled from "styled-components";
import {
  archiveData
} from "../../../../api/backofficeAPI";

export const SignatoryTable = (props) => {
  const [extendedTableOpen, setExtendedTableOpen] = useState(false);
  const [extendedTableData, setExtendedTableData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  function onSelectUser() {
    setIsModalOpen(true);
  }

  function onArchive(){
    const body = {
      sessionId: props.idSession,
      signatoryId: props.signatory?.signatoryId,
    };

    archiveData(body).then((response) => {
      if (response.status === 200) {
         setSuccessMessage(
           "I dati sono stati archiviati"
         );
         props.onRefresh()
      } else {
        setErrorMessage("Non e stato possibile archiviare i dati");
      }
    });
    console.log("on archive clicked ", props.idSession, props.signatory.signatoryId);
  };
  const extendedTableHandler = () => {
    setExtendedTableOpen(!extendedTableOpen);
  };

  const getData = useCallback(() => {
    if (extendedTableOpen) {
      const body = {
        key: props.idSession,
        value: props.signatory.signatoryId,
      };
      getLogSignatory(body).then((response) =>
        setExtendedTableData(response.data)
      );
    }
  }, [extendedTableOpen, props.idSession, props.signatory.signatoryId]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <Table>
        <Thead>
          <Tr>
            <Th className="th-1"></Th>
            <Th className="th-2">Nome</Th>
            <Th className="th-3">Cognome</Th>
            <Th className="th-4">Email</Th>
            <Th className="th-5">Stato</Th>
            <Th className="th-6">Stato Archiviazione</Th>
            <Th className="th-7">Iccid Archiviazione</Th>
            <Th className="th-8">Archivia</Th>
            <Th className="th-9"></Th>
          </Tr>
        </Thead>
        <Tbody>
          <Tr>
            <Td onClick={extendedTableHandler} className="td-1">
              {extendedTableOpen ? (
                <FontAwesomeIcon className="chevron" icon={faAngleUp} />
              ) : (
                <FontAwesomeIcon className="chevron" icon={faAngleDown} />
              )}
            </Td>
            <Td className="td-2">{props.signatory.name}</Td>
            <Td className="td-3">{props.signatory.surname}</Td>
            <Td className="td-4">{props.signatory.email}</Td>
            <Td className="td-5">{props.signatory.statusDescription}</Td>
            <Td className="td-6">{props.signatory.archivingStatus}</Td>
            <Td className="td-7">{props.signatory.archivingId}</Td>
            <Td className="td-8">
              {props.signatory.canArchive? 
              (<FontAwesomeIcon
                  icon={faArchive}
                  onClick={() => onArchive(1)}
                  className={"archive-icon"}
                />)
              :
                (<FontAwesomeIcon
                icon={faCircleMinus}
              />)
            }
            </Td>            
            <Td className="td-9">
              <FontAwesomeIcon                
                className="edit-icon"
                icon={faEdit}
                onClick={() => onSelectUser(1)}
              />
            </Td>
          </Tr>
        </Tbody>
      </Table>

      <div>
      {errorMessage && (
        <div className="error-message">
          <FontAwesomeIcon className="error-icon" icon={faTriangleExclamation} />
          {errorMessage}
        </div>
      )}
      {successMessage && (
        <div className="success-message">
          <FontAwesomeIcon className="success-icon" icon={faCircleCheck} />
          {successMessage}
        </div>
      )}
      </div>
      <SignatoryModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        user={props.signatory}
        idSession={props.idSession}
      />

      {extendedTableOpen && (
        <ExtendedTable>
          <ExtendedThead>
            <ExtendedTr>
              <ExtendedTh className="ex-th-1"></ExtendedTh>
              <ExtendedTh className="ex-th-2">Data</ExtendedTh>
              <ExtendedTh className="ex-th-3">Stato</ExtendedTh>
              <ExtendedTh className="ex-th-4">Descrizione</ExtendedTh>
            </ExtendedTr>
          </ExtendedThead>
          <ExtendedTbody>
            {extendedTableData?.map((item, index) => {
              return (
                <ExtendedTr key={index}>
                  <ExtendedTd className="ex-td-1"></ExtendedTd>
                  <ExtendedTd className="ex-td-2">
                    {dateFormatter(item.timeStamp)}
                  </ExtendedTd>
                  <ExtendedTd className="ex-td-3">
                    {item.description}
                  </ExtendedTd>
                  <ExtendedTd className="ex-td-4">
                    {item.extendDescription}
                  </ExtendedTd>
                </ExtendedTr>
              );
            })}
          </ExtendedTbody>
        </ExtendedTable>
      )}
    </>
    
  );
};

export const Table = styled.table`
  border-collapse: collapse;
  width: fit-content;
  table-layout: fixed;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 2rem;
`;

export const Thead = styled.thead``;
export const Tbody = styled.tbody``;

export const Tr = styled.tr`
  text-align: left;
  border-bottom: solid 1px rgb(157, 166, 173);

  .th-1 {
    width: 40px;
  }
  .th-2 {
    width: 180px;
  }
  .th-3 {
    width: 180px;
  }
  .th-4 {
    width: 230px;
  }
  .th-5 {
    width: 180px;
  }
  .th-6 {
    width: 120px;
  }
  .th-7 {
    width: 120px;
  }
  .th-8 {
   width: 70px;
  }
  .th-9 {
    width: 42px;
  }

  .td-1 {
    width: 40px;
  }
  .td-2 {
    width: 180px;
  }
  .td-3 {
    width: 180px;
  }
  .td-4 {
    width: 230px;
  }
  .td-5 {
    width: 180px;
  }
  .td-6 {
    width: 120px;
  }
  .td-7 {
    width: 120px;
  }
  .td-8 {
    width: 70px;
    justify-content: center;
    align-items: center;
  }
  .td-9 {
    width: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chevron {
    width: 30px;
    color: #152935;
    cursor: pointer;
  }

  .edit-icon {
   color: #0090d1;
   width: 20px;
   height: 20px; 
   cursor: pointer;
  }

  .edit-icon:hover {
    filter: brightness(1.1);
  }
`;

export const Th = styled.th`
  background-color: #f2f5f8;
  font-style: Medium;
  color: #005075;
  line-height: 1rem;
  font-weight: 500;
  height: 40px;
`;

export const Td = styled.td`
  height: 40px;
  font-size: 0.875rem;
  color: #152935;
  font-weight: 400;
`;

// extended table

export const ExtendedTable = styled.table`
  border-collapse: collapse;
  width: fit-content;
  table-layout: fixed;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 4rem;
  
`;

export const ExtendedThead = styled.thead``;
export const ExtendedTbody = styled.tbody``;

export const ExtendedTr = styled.tr`
  text-align: left;
  border-bottom: solid 1px rgb(157, 166, 173);

  .ex-th-1 {
    width: 40px;
  }
  .ex-th-2 {
    width: 220px;
  }
  .ex-th-3 {
    width: 220px;
  }
  .ex-th-4 {
    width: 373px;
  }
`;

export const ExtendedTh = styled.th`
  font-style: Medium;
  color: #005075;
  line-height: 1rem;
  font-weight: 500;
  height: 40px;
`;

export const ExtendedTd = styled.td`
  height: 40px;
  font-size: 0.875rem;
  color: #152935;
  font-weight: 400;
  line-height: 18px;
`;
