// export const AuthenticationTypes = {
//   SPID: "S7uvTHx6sTZ",
//   TOC_TOC: "T3JGELFcC7B",
//   TOC_TOC_SYNC: "YNw9yByKD4W",
//   INVYDIO: "I9Y7YQZnTdT",
//   CERTIFICATE: "Fk4jDLE8nbs",
// };

export const UserRole = {
  TeamSystemAdministrator: 'TeamSystemAdministrator', //10
  TenantAdministrator: "TenantAdministrator", //20
  TenantUser: "TenantUser", //30,
  TenantApiUser: "TenantApiUser", //40
  TenantSupervisor: "TenantSupervisor", // 50
  ExternalAppUser: "ExternalAppUser", // 90
};

export const SortingDirection = {
  Ascending: "Asc",
  Descending: "Desc",
};

// export const AuthenticationRoadMap = {
//   IDENTIFY: "identify",
//   ANAGDATA: "anagdata",
//   ADDITIONALDATA: "additionaldata",
//   SIGN: "sign",
//   SUMMARY: "summary",
// };

// export const AuthReturnErrorMessage = {
//   Spid_return_verification_error: "spid-return-verification-failed",
//   Spid_return_server_error: "spid-return-server-error",
// }

export const SingleSessionTab = {
  statoFirmatari: "statoFirmatari",
  sessione: "sessione",
  detagliFirmatari: "detagliFirmatari"
}

export const IdentificationStatus = {
  IDENTIFIED: 'identification-ok',
  WAITING: 'identification-waiting'
}