import React, { useEffect } from "react";
import styled from "styled-components";
import { AdminForm } from "./AdminForm";
import { useState } from "react";
import Button from "../../../../components/button";
import {
  createTenantAdmin,
  getTenantAdminList,
} from "../../../../api/backofficeAPI";
import { useCallback } from "react";
import Loading from "../../../../components/loading";

export const AdminManagment = (props) => {
  const [adminList, setAdminList] = useState([]);
  const [adminListResponseError, setAdminListResponseError] = useState("");
  const [responseErrors, setResponseErrors] = useState([]);
  const [adminName, setAdminName] = useState("");
  const [adminSurname, setAdminSurname] = useState("");
  const [adminAccountEmail, setAdminAccountEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getError = (fieldName) => {
    return responseErrors.find((obj) => {
      return obj.fieldName === fieldName;
    });
  };

  const getAdminList = useCallback(() => {
    setIsLoading(true);
    getTenantAdminList(props.tenantId).then((response) => {
      if (response.status === 200) {
        setIsLoading(false);
        setAdminList(response.data);
      } else {
        setIsLoading(false);
        setAdminListResponseError(
          "Non e stato possibile ricevere la lista dei admministratori di questo tenant."
        );
      }
    });
  }, [props.tenantId]);

  useEffect(() => {
    getAdminList();
  }, [getAdminList]);

  const createAdmin = useCallback(() => {
    const body = {
      name: adminName,
      surname: adminSurname,
      account: adminAccountEmail,
      note: "",
      isActive: true,
      //webApiEnabled: true,
      userType: "TenantAdministrator",
    };

    createTenantAdmin(props.tenantId, body).then((response) => {
      if (response.status === 200) {
        getAdminList();
        setResponseErrors([]);
      }
      if (response.status === 400) {
        setResponseErrors(response.data);
      } else {
      }
    });
  }, [
    adminAccountEmail,
    adminName,
    adminSurname,
    getAdminList,
    props.tenantId,
  ]);

  return (
    <Wrapper>
      <>
        <AdminList>
          <div className="admin-list-title">
            Lista amministratori del tenant
          </div>
          {isLoading && adminListResponseError.length === 0 && (
            <div className="loading-container">
              <Loading small />
            </div>
          )}
          {adminListResponseError.length > 0 && (
            <div className="error-message">{adminListResponseError}</div>
          )}
          {!isLoading && adminListResponseError.length === 0 && (
            <Ul>
              {adminList?.map((admin, index) => {
                return (
                  <Li key={index}>
                    -&nbsp;{admin.account}
                    {/* <FontAwesomeIcon className="delete-icon" icon={faTrashCan} /> */}
                  </Li>
                );
              })}
            </Ul>
          )}
        </AdminList>
        <AdminForm
          title={"Crea nuovo amministratore"}
          getError={getError}
          adminName={adminName}
          setAdminName={setAdminName}
          adminSurname={adminSurname}
          setAdminSurname={setAdminSurname}
          adminAccountEmail={adminAccountEmail}
          setAdminAccountEmail={setAdminAccountEmail}
          nameError={"Name"}
          surnameError={"Surname"}
          emailError={"Account"}
        />
      </>

      <div className="admin-managment-buttons">
        <Button ghostSmall onClick={props.handleDrawer}>
          Annulla
        </Button>
        <Button type="button" primary className="submit" onClick={createAdmin}>
          Conferma
        </Button>
      </div>
    </Wrapper>
  );
};

export const Wrapper = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  overflow-y: auto;

  .admin-managment-buttons {
    width: 320px;
    display: flex;
    flex-direction: row;
    justify-content: end;
    margin-top: 3rem;
    margin-bottom: 1rem;
  }
`;

export const AdminList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .admin-list-title {
    font-size: 1rem;
    font-weight: 600;
    color: #005075;
    text-align: center;
    margin-bottom: 3rem;
    margin-top: 1rem;
  }

  .delete-icon {
    color: red;
    cursor: pointer;

    &:hover {
      filter: brightness(1.1);
    }
  }

  .error-message {
    color: red;
    font-size: "Roboto";
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }
`;

export const Ul = styled.ol``;

export const Li = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
`;
