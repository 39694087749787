import React from "react";

import {
  faArrowRotateRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UperContainer } from "./style";
import Input from "../input";

function UperFilterControls(props) {

  return (
    <UperContainer className="controls-row-container">
      <div className="filter-controls">
        <FontAwesomeIcon
          icon={faArrowRotateRight}
          className="refresh-icon"
          onClick={props.getData}
        />
        {props.hasRemoveFilters && (
          <div onClick={() => props.refreshData()} className="delete-filters">
            Annulla filtri
          </div>
        )}
      </div>
      <div className="row-container">
        <h6>Da:  </h6>  &nbsp; &nbsp;
        <Input
          type="date"
          placeholder="Inserisci la data del inizio della ricerca"
          name="fromDate"
          value={props.startDate}
          onChange={(e) => props.setStartDate(e.target.value)}
        />
      </div>
      <div className="row-container">
        <h6>A:  </h6>  &nbsp;  &nbsp;
        <Input
          type="date"
          placeholder="Inserisci la data della fine della ricerca"
          name="toDate"
          value={props.endDate}
          onChange={(e) => props.setEndDate(e.target.value)}
        />
        {/* //<InputError message={dataError.birthDate} /> */}
      </div>      
    </UperContainer>
  );
}

export default UperFilterControls;

