import React, { useEffect, useState } from "react";
import { Wrapper } from "./style";
import { useParams } from "react-router-dom";
import Navbar from "../../components/navbar";
import Loading from "../../components/loading";
//import { getCookie } from "../../utils/functions";
import { faCheck, faClose } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getSummaryInfo } from "../../api/portaleAPI";
import { getPortalToken } from "../../api/portaleAPI";

function Summary() {
  const { key1, key2, key3 } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  //const token = getCookie("accesstoken");
  const [token, setToken] = useState("");

  // Fetch the token when key1 changes
  useEffect(() => {
    const fetchToken = async () => {
      setLoading(true);
      try {
        const token = await getPortalToken(key1);
        if (token) {
          setToken(token);
        } else {
          setData({
            type: "error",
            message: "Lei non e autorizzato a vedere questa pagina",
          });
        }
      } catch (err) {
        setData({
          type: "error",
          message: "Lei non e autorizzato a vedere questa pagina",
        });
      } finally {
        setLoading(false);
      }
    };

    if (key1) {
      fetchToken();
    }
  }, [key1]);

  // useEffect(() => {
  //   if (token) {
  //     getSummaryInfo(token, key1, key2, key3)
  //       .then((response) => {
  //         if (response.status === 200) {
  //           setData(response.data);
  //         } else {
  //           setData({
  //             type: "error",
  //             message: "Qualcosa e andato storto",
  //           });
  //         }
  //       })
  //       .catch(() => {
  //         setData({
  //           type: "error",
  //           message: "Qualcosa e andato storto",
  //         });
  //       })
  //       .finally(() => {
  //         setLoading(false);
  //       });
  //   }
  // }, [key1, key2, key3, token]);

  useEffect(() => {
    const fetchData = async () => {
      if (token && key1 && key2 && key3) {
        setLoading(true);
        try {
          const response = await getSummaryInfo(token, key1, key2, key3);
          if (response.status === 200) {
            setData(response.data);
          } else {
            setData({
              type: "error",
              message: "Qualcosa e andato storto",
            });
          }
        } catch (err) {
          setData({
            type: "error",
            message: "Qualcosa e andato storto",
          });
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [token, key1, key2, key3]);

  return (
    <Wrapper>
      <Navbar hasSidebar={false} hasProfile={false} showVersion={false} />
      {loading ? (
        <Loading large />
      ) : (
        <>
          {data?.type === "success" ? (
            <FontAwesomeIcon icon={faCheck} className="icon" />
          ) : (
            <FontAwesomeIcon icon={faClose} className="error-icon" />
          )}
          <h3 className="text">{data?.message}</h3>
        </>
      )}
    </Wrapper>
  );
}

export default Summary;
