import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  createUser,
  createUserVerification,
} from "../../../../api/backofficeAPI";
import Button from "../../../../components/button";
import { DetailedTooltip } from "../../../../components/detailedTooltip";
import Input from "../../../../components/input";
import Select from "../../../../components/select";
import Toggler from "../../../../components/toggler";
import { Wrapper } from "../../../styles/userForm";
import TooltipData from "../backoffice/TooltipData";

const NewUserForm = (props) => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState("TenantUser");
  const [isActive, setIsActive] = useState(true);
  const [responseErrors, setResponseErrors] = useState([]);
  const [remainingUserToCreate, setRemainingUserToCreate] = useState(1);
  const [canCreateUser, setCanCreateUser] = useState(true);

  useEffect(() => {
    createUserVerification(props.tenantId).then((response) =>
      setRemainingUserToCreate(response.data)
    );
  }, [props.tenantId]);

  useEffect(() => {
    if (type === "TenantUser" || type === "TenantSupervisor") {
      setCanCreateUser(remainingUserToCreate > 0);
    } else {
      setCanCreateUser(true);
    }
  }, [remainingUserToCreate, type]);

  const onFormSubmit = () => {
    let body = {
      id: null,
      name: name,
      surname: surname,
      account: email,
      note: "inserimento nuovo utente",
      isActive: isActive,
      userType: type,
    };
    createUser(body).then((response) => {
      if (response.status === 400) {
        setResponseErrors(response.data);
      } else if (response.status === 200) {
        props.getData();
        props.handleDrawer(false);
      } else {
      }
    });
  };

  const getError = (field) => {
    return responseErrors.find((obj) => {
      return obj.fieldName === field;
    });
  };

  return (
    <Wrapper>
      <div className="form">
        <div className="upper-section">
          <div className="input-container">
            <label className="form-label" htmlFor="tenant">
              Nome
            </label>
            <Input
              value={name}
              id="nomeUtente"
              name="nomeUtente"
              type="text"
              placeholder="nome"
              onChange={(e) => setName(e.target.value)}
            />
            {getError("Name") && (
              <div className="error-message">{getError("Name").message}</div>
            )}
          </div>
          <div className="input-container">
            <label className="form-label" htmlFor="tenant">
              Cognome
            </label>
            <Input
              value={surname}
              id="cognomeUtente"
              name="cognomeUtente"
              type="text"
              placeholder="Cognome"
              onChange={(e) => setSurname(e.target.value)}
            />
            {getError("Surname") && (
              <div className="error-message">{getError("Surname").message}</div>
            )}
          </div>
          <div className="input-container">
            <label className="form-label" htmlFor="tenant">
              Email
            </label>
            <Input
              value={email}
              id="emailUtente"
              name="emailUtente"
              type="text"
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
            />
            {getError("Account") && (
              <div className="error-message">{getError("Account").message}</div>
            )}
          </div>
          <div className="select-container">
            <label className="form-label" htmlFor="tenant">
              Tipo
              <div className="tooltip-icon">
                <DetailedTooltip
                  title="Informazione su i tipi di utenti"
                  children={<TooltipData />}
                ></DetailedTooltip>
              </div>
            </label>
            <Select
              value={type}
              name="profile"
              id="profile"
              onChange={(e) => setType(e.target.value)}
            >
              <option value="TenantUser">Normale</option>
              <option value="TenantSupervisor">Supervisore</option>
              <option value="TenantAdministrator">Amministratore</option>
              <option value="TenantApiUser">Utente Api</option>
            </Select>
          </div>
          {getError("userType") && (
            <div className="error-message">{getError("userType").message}</div>
          )}
          <div className="toggler-container">
            <Toggler hasMargin checked={isActive} setIsChecked={setIsActive} />
            {getError("isAvtive") && (
              <div className="error-message">
                {getError("isAvtive").message}
              </div>
            )}
            <div>Attivo</div>
          </div>
        </div>
        {/* Buttons */}
        <div className="lower-section">
          <div className="buttons">
            <Button ghostSmall onClick={() => props.handleDrawer(false)}>
              Annulla
            </Button>
            <Button
              disabled={!canCreateUser}
              onClick={onFormSubmit}
              type="button"
              primary
              className="submit"
            >
              Conferma
            </Button>
          </div>

          {canCreateUser ? null : (
            <div className="warning-message">
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="warning-icon"
              />
              {"Hai ragiunto il numero massimo di utenti attivi creati."}
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default NewUserForm;
