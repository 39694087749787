import React, { useState } from "react";
import logo from "../../../assets/images/tsLogoColor.png";
import Button from "../../../components/button";
import Input from "../../../components/input";
import { useNavigate } from "react-router-dom";
import { forgotPasswordEmailHandler } from "../../../api/backofficeAPI";
import Loading from "../../../components/loading";
import { useCallback } from "react";
import { Card, Wrapper } from "./style";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [emaillSuccess, setEmailSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = useCallback(() => {
    setIsLoading(true);
    setEmailSuccess("");
    setEmailError("");
    forgotPasswordEmailHandler(email).then((response) => {
      if (response.status === 200) {
        setEmailSuccess(response.data);
        setIsLoading(false);
      } else {
        setEmailError("Non e stato possibile inviare la mail.");
        setIsLoading(false);
      }
    });
  }, [email]);

  return (
    <Wrapper>
      <Card>
        <div>
          <img className={"logo"} src={logo} alt={"ts-logo"} />
        </div>
        <div className="text">
          Inserisci la tua Email per ricevere la nuova password
        </div>
        <Input
          className={`email-input ${emailError && "email-input-error"}`}
          id="email"
          name="email"
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {emailError && <div className="error-message">{emailError}</div>}
        {emaillSuccess && (
          <div className="success-message">{emaillSuccess}</div>
        )}
        {!emaillSuccess && !isLoading && (
          <Button primary onClick={handleSubmit} className="login-form-button">
            Invia Email
          </Button>
        )}
        {emaillSuccess && !isLoading && (
          <div className="text margin-top" onClick={handleSubmit}>
            {"Mail non arrivata?"}&nbsp;
            <span className="resend-mail-link">Invia di nuovo</span>
          </div>
        )}
        {isLoading && (
          <div className="loading-container">
            <Loading small />
          </div>
        )}
        {!isLoading && (
          <div onClick={() => navigate("/login")} className="login-link">
            Login
          </div>
        )}
      </Card>
    </Wrapper>
  );
};

export default ForgotPassword;
