import React from "react";
import styled from "styled-components";
import {
  faArrowRotateRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function PageControls(props) {
  return (
    <ControlsContainer className="controls-row-container">
      <div className="filter-controls">
        <FontAwesomeIcon
          icon={faArrowRotateRight}
          className="refresh-icon"
          onClick={props.getData}
        />
        {props.hasRemoveFilters && (
          <div onClick={() => props.refreshData()} className="delete-filters">
            Annulla filtri
          </div>
        )}
      </div>     
    </ControlsContainer>
  );
}

export default PageControls;

const ControlsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  .filter-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .page-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .refresh-icon {
    color: #0090d1;
    height: 1.5rem;
    cursor: pointer;
  }

  .delete-filters {
    color: #0090d1;
    margin-left: 2rem;
    cursor: pointer;
  }

  .row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .pages-input {
    width: 4rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .left-arrow {
    margin-left: 1rem;
    margin-right: 1.5rem;
    color: #0090d1;
    cursor: pointer;
  }

  .right-arrow {
    color: #0090d1;
    cursor: pointer;
  }

  .margin-left-4 {
    margin-left: 4rem;
  }

  .row-per-page-select {
    width: 4rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
`;
