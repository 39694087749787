import styled from "styled-components";
export const UperContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 40%;

  .filter-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .refresh-icon {
    color: #0090d1;
    height: 1.5rem;
    cursor: pointer;
  }

  .delete-filters {
    color: #0090d1;
    margin-left: 2rem;
    cursor: pointer;
  }

  .row-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;