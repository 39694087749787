import React, { useEffect, useState } from "react";
import {
  ButtonContainer,
  Wrapper,
  Divider,
  IdentificationCard,
  IdentificationSelectContainer,
  InformationContainer,
  IdentificationSectionContainer,
  PageTitle,
  PageDescription,
  ContentContainer,
  NameContainer,
} from "./style";
import Button from "../../../../components/button";
import { faUserShield, faVideoCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import Loading from "../../../../components/loading";
import Input from "../../../../components/input";
import InfoBox from "./infoBox";
import SpidInfoBox from "./spidInfoBox";
import CieInfoBox from "./cieInfoBox";
import SyncVideoInfoBox from "./syncVideoInfoBox";
import AsyncVideoInfoBox from "./asyncVideoInfoBox";

function Recognition(props) {
  const {
    supportedRecognitionModes,
    setSessionData,
    handleStorageData,
    handleStorageStep,
    formSteps,
    handleToPreviousStep,
    sessionData,
  } = props;
  const [selectedIdentificationMethods, setSelectedIdentificationMethods] =
    useState([]);
  const [identificationInfo, setIdentificationInfo] = useState("");
  const IdentificationMethods = {
    SPID: { name: "SPID", icon: faUserShield },
    TOCTOC: { name: "Video self", icon: faVideoCamera },
    TOCTOCSYNC: { name: "Video con operatore", icon: faVideoCamera },
    CIE: { name: "CIE", icon: faUserShield }
  };
  const navigate = useNavigate();

  useEffect(() => {
    sessionData.identificationMethods &&
      setSelectedIdentificationMethods([...sessionData.identificationMethods]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowInformation = (id) => {
    setIdentificationInfo(id);
  };

  const handleCardSelect = (event, id) => {
    event.stopPropagation();
    setSelectedIdentificationMethods((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((methodId) => methodId !== id);
      } else {
        return [...prevSelected, id];
      }
    });
  };

  const handleSubmit = () => {
    setSessionData((prev) => ({
      ...prev,
      identificationMethods: [...selectedIdentificationMethods],
    }));
    handleStorageData({
      identificationMethods: [...selectedIdentificationMethods],
    });
    handleStorageStep(formSteps[2]);
    navigate("/create-session/subjects");
  };

  const RenderInformation = () => {
    switch (identificationInfo) {
      case "SPID":
        return <SpidInfoBox />;
      case "TOCTOC":
        return <AsyncVideoInfoBox />;
      case "TOCTOCSYNC":
        return <SyncVideoInfoBox />;
      case "CIE":
        return <CieInfoBox />
      default:
        return <InfoBox />;
    }
  };

  return (
    <Wrapper>
      <ContentContainer>
        <PageTitle>Scegli la modalità di riconoscimento</PageTitle>
        <PageDescription>
          Seleziona i metodi di riconoscimento che vuoi mettere a disposizione
          dei soggetti per questa sessione. Ogni soggetto riceverà un’email con
          le istruzioni per portare a termine l’identificazione.
        </PageDescription>
        <IdentificationSectionContainer>
          <IdentificationSelectContainer>
            {supportedRecognitionModes.length > 0 ? (
              supportedRecognitionModes.map((method, index) => {
                return IdentificationMethods[method] ? (
                  <IdentificationCard
                    key={index}
                    onClick={() => handleShowInformation(method)}
                    selected={identificationInfo === method}
                  >
                    <NameContainer>
                      <FontAwesomeIcon
                        className="icon"
                        icon={IdentificationMethods[method].icon}
                      />
                      {IdentificationMethods[method].name}
                    </NameContainer>
                    <Input
                      type="checkbox"
                      onChange={(e) => handleCardSelect(e, method)}
                      value={method}
                      checked={selectedIdentificationMethods.includes(method)}
                    />
                  </IdentificationCard>
                ) : null;
              })
            ) : (
              <Loading large />
            )}
          </IdentificationSelectContainer>
          <Divider />
          <InformationContainer>
            <RenderInformation />
          </InformationContainer>
        </IdentificationSectionContainer>
      </ContentContainer>

      <ButtonContainer>
        <Button ghost margin8 onClick={handleToPreviousStep}>
          Indietro
        </Button>
        <Button
          primaryLarge
          disabled={selectedIdentificationMethods.length === 0}
          onClick={handleSubmit}
        >
          Avanti
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
}

export default Recognition;
