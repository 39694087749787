import React from "react";
import { listAllUsers } from "../../../api/backofficeAPI";
import { useState, useEffect } from "react";
import { useUserContext } from "../../../context/userContext";
import Button from "../../../components/button";
import { useCallback } from "react";
import Loading from "../../../components/loading";
import UserDrawer from "../../components/backofficeTenant/drawer/UserDrawer";
import ErrorState from "../../../components/errorState";
import UsersTable from "../../components/backofficeTenant/backoffice/UsersTable";
import PageControls from "../../../components/pageControls";
import PaginationControls from "../../../components/paginationControls";
import { Wrapper } from "./style";

const TenantBackoffice = () => {
  const [listaUtenti, setListaUtenti] = useState([]);
  const { myUser } = useUserContext();
  const [utente, setUtente] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalPageNumber, setTotalPageNumber] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [requestError, setRequestError] = useState(false);

  const getData = useCallback(() => {
    setIsLoading(true);

    listAllUsers(pageNumber, rowsPerPage, [], []).then((response) => {
      if (response.status === 200) {
        setListaUtenti(response.data.data);
        setTotalPageNumber(response.data.totalPages);
        setTotalRecords(response.data.totalRecords);
        setIsLoading(false);
      } else {
        setRequestError(true);
        setIsLoading(false);
      }
    });
  }, [pageNumber, rowsPerPage]);

  useEffect(() => {
    getData();
  }, [getData]);

  const handleDrawer = (bool) => {
    setIsOpen(bool);
  };

  const decreasePageNumber = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const onRighePerPaginaChange = (e) => {
    setRowsPerPage(e.target.value);
    setPageNumber(1);
  };

  return (
    <Wrapper>    
      <div className="content">
      {!isLoading && listaUtenti.length > 0 && !requestError && (
          <div className="button-container">
            <Button
              primary
              className="button"
              onClick={() => {
                setIsOpen(true);
                setIsNew(true);
              }}
            >
              Nuovo Utente
            </Button>
          </div>
        )}
        <PageControls
          getData={getData}
          refreshData={() => { }}
        />

        <UsersTable
          isLoading={isLoading}
          listaUtenti={listaUtenti}
          requestError={requestError}
          setIsNew={setIsNew}
          setIsOpen={setIsOpen}
          setUtente={setUtente}
        />

        <PaginationControls
          getData={getData}
          refreshData={() => { }}
          totalPageNumber={totalPageNumber}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          decreasePageNumber={decreasePageNumber}
          rowsPerPage={rowsPerPage}
          onRighePerPaginaChange={onRighePerPaginaChange}
          totalRecords={totalRecords}
        />

        {isLoading && !requestError && (
          <div className="loading-container">
            <Loading large />
          </div>
        )}

        {!listaUtenti.length && requestError && !isLoading && (
          <ErrorState
            text={
              "A causa di un errore non e stato possibile ricevere la lista degli utenti!"
            }
          />
        )}

        <UserDrawer
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isNew={isNew}
          myUser={myUser}
          listAllUsers={listAllUsers}
          getData={getData}
          handleDrawer={handleDrawer}
          utente={utente}
        />
      </div>
    </Wrapper>
  );
};

export default TenantBackoffice;