import axios from "axios";
import { Axios, headers } from "./AxiosConfig";
const baseURL = process.env.REACT_APP_API_KEY;

// post request that is used by the TeamSystem admin to get all the tenants(companies) registred on the platform
export const listAllCompaniess = async (data) => {
  try {
    const response = await Axios.post(
      "/Provisioning/getpagedtenats",
      data,
      headers
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

// Put request used by the TeamSystem Admin to Edit the data of an existing tenant(Company)
export const editCompany = async (body) => {
  try {
    const response = await Axios.put("Provisioning/", body, headers);
    return response;
  } catch (err) {
    return err.response;
  }
};

// Post request used by the TeamSystem Admin to register(create) a new tenant(Company)
export const createNewCompany = async (body) => {
  try {
    const response = await Axios.post("Provisioning/", body, headers);
    return response;
  } catch (err) {
    return err.response;
  }
};

// Post request made by the tenant admin that gets in the response the list of all users of a specific tenant
// and fetches them in dhe dashboard of the tenant admin.
export const listAllUsers = async (pageNumber, pageSize, filters, sorting) => {
  let body = {
    pageNumber: pageNumber,
    pageSize: pageSize,
    filters: filters,
    sorting: sorting,
    fieldsToInclude: [],
  };
  try {
    const response = await Axios.post(`User/getpagedusers`, body, headers);
    return response;
  } catch (err) {
    return err.response;
  }
};

// Get request used by the User of the Teant to get the data of a single Session.
export const getSingleSession = async (id) => {
  try {
    const response = Axios.get(
      `SessionManager/infosession/${id}`,
      null,
      headers
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getSignatoriesInfo = async (id) => {
  try {
    const response = await Axios.get(
      `SessionManager/getsignatoriesinfo/${id}`,
      headers
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

// Post request used by the Tenant User to get the logs of a single session
export const getLogSession = async (id) => {
  try {
    const response = await Axios.post(
      `SessionManager/getlogsession/${id}`,
      headers
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

// Post request used by the User of the Tenant to get a list of logs of a specific signatory.
export const getLogSignatory = async (body) => {
  try {
    const response = Axios.post(
      "SessionManager/getlogsignatory",
      body,
      headers
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

// Post request used by the User of the Tenant to get the list of all sessions asigned to him.
export const getPaginatedSessions = async (body) => {
  try {
    const response = await Axios.post(
      "SessionManager/getpagedsessionsinfo",
      body,
      headers
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

// Delete request used by the User of the Tenant to delete multiple selected sessions.
export const deleteMultipleSessions = async (sessionsIdList) => {
  try {
    const response = await Axios.delete(
      `SessionManager/deletemany`,
      { data: sessionsIdList },
      headers
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

// Delete request used by the User of the Tenant to delete a single session.
export const deleteSession = async (id) => {
  try {
    const response = await Axios.delete(`SessionManager/delete/${id}`, headers);
    return response;
  } catch (err) {
    return err.response;
  }
};

// Post request used by the administrator of the tenant to create a new user,
// with different type of roles such as admin, supervisor or normal.
export const createUser = async (userData) => {
  try {
    const response = await Axios.post(`User`, userData, headers);
    return response;
  } catch (err) {
    return err.response;
  }
};

// Put request used by the administrator of the tenant to edit an existing user of this tenant.
export const editUser = async (body) => {
  try {
    const response = await Axios.put(`User`, body, headers);
    return response;
  } catch (err) {
    return err.response;
  }
};

// Delete request used by the administrator of the tenant to delete an existing user of this tenant.
export const deleteUser = async (id) => {
  try {
    const response = await Axios.delete(`User/${id}`, headers);
    return response;
  } catch (err) {
    return err.response;
  }
};

//  Get request used by the tenant administrator to check if a user of the tenant can be deleted or not.
export const deleteCheck = async (id) => {
  try {
    const response = await Axios.get(`User/candelete/${id}`, headers);
    return response;
  } catch (err) {
    return err.response;
  }
};

// get request made by the tenant admin by passing the tenant id, to check if this tenant can create or not new users.
export const createUserVerification = async (id) => {
  try {
    const response = await Axios.get(
      `Provisioning/getnrofremainingusers?tenatId=${id}`,
      headers
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

export const forgotPasswordEmailHandler = async (email) => {
  try {
    const body = {
      userName: email,
    };
    const response = await Axios.post(`Auth/token`, body);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const resetPassword = async (body) => {
  try {
    const response = await Axios.post(`Auth/resetpassword`, body);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getTenantAdminList = async (id) => {
  try {
    const response = await Axios.get(`User/tenant/${id}`);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const createTenantAdmin = async (id, data) => {
  try {
    const response = await Axios.post(`User/tenant/${id}`, data);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const resendEmailToSignatory = async (data) => {
  try {
    const response = await Axios.post(
      `/SessionManager/resendmailtosignatory`,
      data
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

export const updateSignatoryData = async (sessionId, data) => {
  try {
    const response = await Axios.put(
      `SessionManager/updatesignatory/${sessionId}`,
      data
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

export const changePassword = async (data) => {
  try {
    const response = await Axios.post(`Auth/changepassword`, data);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const resendDocuments = async (data) => {
  try {
    const response = await Axios.post(
      `SessionManager/resendsigneddocumenttosignatory`,
      data
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getConfigTenantApp = async () => {
  try {
    const response = await Axios.get(`Provisioning/getconfigtenantapp`);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const createProcess = async () => {
  try {
    const response = await Axios.post(`Process/create`);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const deleteProcess = async (sessionId) => {
  try {
    const response = await Axios.delete(`Process/${sessionId}`);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getApiToken = async () => {
  try {
    const response = await Axios.get(`SessionManager/getapitoken`);
    return response;
  } catch (err) {
    return err.response;
  }
};

export const startSession = async (data, token) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await axios.post(
      `${baseURL}SessionManager/startsession`,
      data,
      config
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

export const uploadDocuments = async (sessionId, data) => {
  try {
    const response = await Axios.put(
      `Process/uploadDocuments/${sessionId}`,
      data
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

export const getDocument = async (sessionId, index) => {
  try {
    const response = await Axios.get(
      `Process/getFieldCoordinates/${sessionId}/${index}`
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

export const sendAssignedDocument = async (sessionId, index, data) => {
  try {
    const response = await Axios.put(
      `Process/assignSignatories/${sessionId}/${index}`,
      data
    );
    return response;
  } catch (err) {
    return err.response;
  }
};

export const archiveData = async (data) => {
  try {
    const response = await Axios.post(`${baseURL}Archive/signatory`, data);
    return response;
  } catch (err) {
    return err.response;
  }
};
