import React from "react";
import { Drawer } from "@mui/material";

function SingleSessionSidebar(props) {

  return (
    <Drawer
      anchor="right"
      open={props.isOpen}
      onClose={() => {
        props.setIsOpen(false);
      }}
      PaperProps={{
        sx: {
          width: "1250px",
          overflow: "auto",
          overflowY: "scroll",
          margin: "48px 0 0 0 "
        },
      }}
      variant="temporary"
    >
      {props.children}
    </Drawer>
  );
}

export default SingleSessionSidebar;
