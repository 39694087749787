export const dateFormatter = (date) => {
  let dt = new Date(date);

  return `${dt.getDate().toString().padStart(2, "0")}/${(dt.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${dt.getFullYear().toString().padStart(4, "0")} ${dt
    .getHours()
    .toString()
    .padStart(2, "0")}:${dt.getMinutes().toString().padStart(2, "0")}:${dt
    .getSeconds()
    .toString()
    .padStart(2, "0")}`;
};

export const formatedDate = (date) => {
  if (date) {
    return new Date(date).toISOString().substr(0, 10);
  } else {
    return "";
  }
};

export function getFirstLetter(str) {
  return str?.charAt(0)?.toUpperCase();
}

export function hasEmptyFields(obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (value === null || value === undefined || value === "") {
        return true;
      }
    }
  }
  return false;
}

export function combineFirstLetters(str1, str2) {
  if (!str1 || !str2) {
    return "N/A";
  }

  const firstLetterStr1 = str1.charAt(0).toUpperCase();
  const firstLetterStr2 = str2.charAt(0).toUpperCase();

  return firstLetterStr1 + firstLetterStr2;
}

export const fileToBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

// export const getCookie = (name) => {
//   const value = `; ${document.cookie}`;
//   const parts = value.split(`; ${name}=`);
//   if (parts.length === 2) return parts.pop().split(";").shift();
// }
